<template>
    <div v-if="jobs.length > 0" class="job__container">
        <div v-for="job in jobs" :key="job.id" class="job">
            <div class="job__header">
                <h2 class="job__title">{{ job.title }}</h2>
                <h5 class="job__company">By: {{ job.company_name}}</h5>
            </div>
            <div class="job__body">
                <div class="job__description" v-html="job.description.substring(0, 50)"></div>
            </div>
            <div class="job__footer">
                <span class="job__location">{{ job.location }}</span>
                <span class="job__posted">{{  job.detected_extensions.posted_at }}</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'JobsWidget',
  data() {
    return {
      count: 30,
    };
  },
  created() {
    this.$highway.singleDispatcher('thirdPartyIntegrations/getJobs');
  },
  computed: {
    jobs() {
      console.log('xvf', this.$store.state.thirdPartyIntegrations.jobs);
      return this.$store.state.thirdPartyIntegrations.jobs;
    },
  },
};
</script>

<style lang="scss">
.job {
    &__container {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
    }

    margin: 1rem;
    width: 270px;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.125);
    position: relative;
    padding: 1rem;
    background: #fff;
    &__header {
        border-bottom: 1px solid rgba(0, 0, 0, 0.125);
        padding-bottom: .5rem;
    }

    &__title {
        font-size: 1rem;
    }
    &__company {
        font-size: .8rem;
        font-style: italic;
    }

    &__body {
        // border-bottom: 1px solid rgba(0, 0, 0, 0.125);
        padding: 1rem 0;
        padding-bottom: 2rem;
    }

    &__description {
        font-size: .8rem;

    }

    &__footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: absolute;
        bottom: 5px;
        left: 0;
        width: 100%;
        padding: 0 1rem;
    }

    &__location {
        font-size: .7rem;
    }

    &__posted {
        font-size: .7rem;
        font-style: italic;
    }
}
</style>
